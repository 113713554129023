import { NavigationItemParams } from '@/presentation/components/BottomNavigation/component';
import { NavigationIcon } from '@/presentation/components/BottomNavigation/components/NavigationIcon';
import { Anchor } from '@/presentation/components/BottomNavigation/style';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { House } from '@pelando/fontawesome/icons';
import Link from 'next/link';

export function HomeNavigationItem({
  onClick,
  active,
  count,
}: NavigationItemParams) {
  const { t } = useTranslation('header');

  return (
    <Link href="/" passHref prefetch={false} legacyBehavior>
      <Anchor onClick={onClick} aria-current={active}>
        <NavigationIcon icon={House} count={count} />
        <span>{t('bottom-navigation-home-text')}</span>
      </Anchor>
    </Link>
  );
}
