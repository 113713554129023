import { NavigationItemParams } from '@/presentation/components/BottomNavigation/component';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { getComparatorHomeUrl } from '@/presentation/services/urls';
import { Theme } from '@pelando/components';
import Link from 'next/link';
import { useEffect, useId, useRef } from 'react';
import ComparatorIcon from '@/resources/comparator/logos/icon.svg?component';
import { useRouter } from 'next/router';
import { ComparatorItem } from './style';

export function ComparatorNavigationItem({
  onClick,
  active,
}: NavigationItemParams) {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  const elementRef = useRef<HTMLAnchorElement>(null);
  const randomID = useId();

  useEffect(() => {
    const linearGradient = elementRef.current?.querySelector(
      'linearGradient'
    ) as unknown as HTMLElement;

    const graph = elementRef.current?.querySelector(
      '#small-icon-gradient'
    ) as HTMLElement;

    if (linearGradient) linearGradient.id = randomID;
    if (graph) graph.style.fill = active ? `url(#${randomID})` : '#999999';
  }, [active, randomID]);

  return (
    <Link
      href={getComparatorHomeUrl(locale)}
      passHref
      prefetch={false}
      legacyBehavior
    >
      <ComparatorItem ref={elementRef} onClick={onClick} aria-current={active}>
        <ComparatorIcon
          style={{ marginBottom: 4 }}
          color={active ? `rgb(${Theme.colors.Alpha})` : undefined}
        />
        <span>{t('comparator-text')}</span>
      </ComparatorItem>
    </Link>
  );
}
