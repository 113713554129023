import { SearchType } from '@/presentation/pages/public/Search/types';

const LOCALSTORAGE_ONBOARDING_CONTEXT_SEARCH_STAGE =
  'ONBOARDING_CONTEXT_SEARCH_STAGE';

export function addOnboardingContextSearchStageLocalStorage(step?: string) {
  return window.localStorage.setItem(
    LOCALSTORAGE_ONBOARDING_CONTEXT_SEARCH_STAGE,
    step || SearchType.ALL
  );
}

export function removeOnboardingContextSearchStageLocalStorage() {
  window.localStorage.removeItem(LOCALSTORAGE_ONBOARDING_CONTEXT_SEARCH_STAGE);
}

export function getOnboardingContextSearchStageLocalStorage() {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(
      LOCALSTORAGE_ONBOARDING_CONTEXT_SEARCH_STAGE
    );
  }
  return undefined;
}
