import {
  BodyHeavy,
  BodyLight,
  Button,
  Colors,
  H2Heavy,
  Icon,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const ShieldCheckIcon = styled(Icon)`
  font-size: 48px;
  color: rgb(${Theme.colors.Brand});
`;

export const Title = styled.h1`
  ${H2Heavy}
  margin: 16px 0 24px 0;
`;

export const Content = styled.p`
  ${BodyLight}
  color: rgb(${Colors.Gray});
  margin-bottom: 24px;
  a {
    ${BodyHeavy}
    color: rgb(${Colors.Gray});
  }
`;

export const ConfirmButton = styled(Button)`
  margin-top: 8px;
  height: 48px;
  width: 100%;
`;
