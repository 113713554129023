import { useRouter } from 'next/router';
import { useState } from 'react';

type UseShowAccessToastFeedProps = {
  showAppInstallIncentives: boolean;
};

export const useShowAccessToastFeed = ({
  showAppInstallIncentives,
}: UseShowAccessToastFeedProps) => {
  const [hasBanner, setHasBanner] = useState<boolean>(true);
  const { pathname } = useRouter();
  const isHomePage = pathname === '/[slug]';

  const isShowToast = hasBanner && showAppInstallIncentives && isHomePage;

  const handleToastClose = () => {
    setHasBanner(false);
  };

  return { isShowToast, handleToastClose };
};
