import { Icon, HeaderLogo as FooterLogo } from '@pelando/components';
import React from 'react';
import {
  GooglePlay,
  Apple,
  Instagram,
  Tiktok,
  XTwitter,
  FacebookF,
  Bluesky,
} from '@pelando/fontawesome/icons';
import {
  getBlueskyUrl,
  getFacebookUrl,
  getInstagramUrl,
  getInternationalLink,
  getTikTokUrl,
  getTwitterUrl,
} from '@/presentation/services/urls/socialMedia';
import { useLocale } from '@/presentation/hooks/useLocale';
import Translation from '../../components/Translation';
import { useTranslation } from '../../hooks/useTranslation';
import {
  getAllStoresUrl,
  getContactPelandoUrl,
  getPelandoHelpCenterUrl,
} from '../../services/urls';
import {
  FooterAdress,
  FooterContainer,
  FooterContent,
  FooterList,
  FooterListItem,
  FooterLogoText,
  FooterSubText,
  FooterText,
  FooterTitle,
  FooterAccess,
  FooterStoreUrl,
  FooterFollowUrl,
  FooterInfo,
  FooterApp,
  FooterDivisorLine,
  FooterListItemLink,
  FooterStoreUrlButton,
  FooterFollowIcons,
  FooterTitleInList,
  FooterAccessApp,
  QRCodeImage,
  FooterAccessMobile,
  FooterSubtitleApp,
  FooterGapFollowUrl,
  FooterListItemLinkImage,
  FooterItemImage,
  InternationalWrapper,
} from './style';

export type RouterLinkItem = {
  title: string;
  url: string;
};
export type FooterProps = {
  removeMargin?: boolean;
};

export function Footer({ removeMargin }: FooterProps) {
  const { t } = useTranslation('footer');
  const locale = useLocale();
  const institutionalRouterUrl = [
    {
      title: t('title-privacy-policy'),
      url: t('link-privacy-policy'),
    },
    {
      title: t('title-terms-of-service'),
      url: t('link-terms-of-service'),
    },
    {
      title: t('title-code-of-conduct'),
      url: t('link-code-of-conduct'),
    },
  ];

  const supportRouterUrl = [
    { title: t('support-stores'), url: getAllStoresUrl(locale) },
    { title: t('support-contact-us'), url: getContactPelandoUrl(locale) },
  ];

  const storeUrl = {
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.tippingcanoe.pelando',
    appleStore:
      'https://apps.apple.com/br/app/pelando-promo%C3%A7%C3%B5es-e-cupons/id1045614200',
  };

  const isBR = locale === 'pt-BR';
  const facebookUrl = getFacebookUrl(locale);
  const blueskyUrl = getBlueskyUrl(locale);

  return (
    <FooterContainer $removeMargin={removeMargin}>
      <FooterContent>
        <FooterLogoText>
          <FooterLogo url="/" locale={locale} />
          <FooterText>{t('footer-text')}</FooterText>
          <FooterSubText>{t('footer-subtext')}</FooterSubText>
        </FooterLogoText>
        <FooterDivisorLine />
        <FooterInfo>
          <FooterList>
            <FooterTitleInList>{t('institutional')}</FooterTitleInList>
            {institutionalRouterUrl.map((item) => (
              <FooterListItem key={item.url}>
                <FooterListItemLink url={item.url}>
                  {item.title}
                </FooterListItemLink>
              </FooterListItem>
            ))}
          </FooterList>
          <FooterList>
            <FooterTitleInList>{t('support')}</FooterTitleInList>
            {supportRouterUrl.map((item) => (
              <FooterListItem key={item.url}>
                <FooterListItemLink url={item.url} target="_blank">
                  {item.title}
                </FooterListItemLink>
              </FooterListItem>
            ))}
            <FooterListItem>
              <FooterListItemLink
                url={getPelandoHelpCenterUrl(locale)}
                rel="nofollow"
              >
                {t('support-help-center')}
              </FooterListItemLink>
            </FooterListItem>
          </FooterList>
        </FooterInfo>
        <FooterDivisorLine />
        {isBR ? (
          <FooterAccessMobile>
            <FooterTitle>{t('download-the-app')}</FooterTitle>
            <FooterApp>
              <FooterStoreUrl url={storeUrl.googlePlay} target="_blank">
                <FooterStoreUrlButton>
                  <Icon icon={GooglePlay} role="img" style={{ fontSize: 24 }} />
                  Google play
                </FooterStoreUrlButton>
              </FooterStoreUrl>
              <FooterStoreUrl url={storeUrl.appleStore} target="_blank">
                <FooterStoreUrlButton>
                  <Icon icon={Apple} role="img" style={{ fontSize: 24 }} />
                  App store
                </FooterStoreUrlButton>
              </FooterStoreUrl>
            </FooterApp>
          </FooterAccessMobile>
        ) : null}
        <FooterAccess>
          <FooterTitle>{t('follow')}</FooterTitle>
          <FooterGapFollowUrl>
            <FooterFollowUrl
              url={getInstagramUrl(locale)}
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
            >
              <FooterFollowIcons icon={Instagram} />
            </FooterFollowUrl>
            {facebookUrl ? (
              <FooterFollowUrl
                url={facebookUrl}
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <FooterFollowIcons icon={FacebookF} />
              </FooterFollowUrl>
            ) : null}
            <FooterFollowUrl
              url={getTwitterUrl(locale)}
              target="_blank"
              rel="noreferrer"
              aria-label="X/Twitter"
            >
              <FooterFollowIcons icon={XTwitter} />
            </FooterFollowUrl>
            {blueskyUrl ? (
              <FooterFollowUrl
                url={blueskyUrl}
                target="_blank"
                rel="noreferrer"
                aria-label="Bluesky"
              >
                <FooterFollowIcons icon={Bluesky} />
              </FooterFollowUrl>
            ) : null}
            <FooterFollowUrl
              url={getTikTokUrl(locale)}
              target="_blank"
              rel="noreferrer"
              aria-label="TikTok"
            >
              <FooterFollowIcons icon={Tiktok} />
            </FooterFollowUrl>
          </FooterGapFollowUrl>
          {isBR ? (
            <InternationalWrapper>
              <FooterTitle>{t('international')}</FooterTitle>
              <FooterListItem as="div">
                <FooterListItemLinkImage url={getInternationalLink(locale)}>
                  <FooterItemImage />
                  {t('footer-international-title')}
                </FooterListItemLinkImage>
              </FooterListItem>
            </InternationalWrapper>
          ) : null}
        </FooterAccess>
        {isBR ? (
          <FooterAccessApp>
            <FooterTitle>
              <Translation translation={t('footer-app-title')}>
                Acesse descontos <br /> exclusivos no App
              </Translation>
            </FooterTitle>
            <FooterSubtitleApp>{t('footer-app-subtitle')}</FooterSubtitleApp>
            <QRCodeImage />
          </FooterAccessApp>
        ) : null}
      </FooterContent>
      {isBR && (
        <FooterAdress data-testid="footer-address">
          <span>Av. Getúlio Vargas, 1492 - Funcionários, </span>{' '}
          <span>Belo Horizonte - MG - CEP 30112-021</span>
        </FooterAdress>
      )}
    </FooterContainer>
  );
}
