import { StaticImageData } from 'next/image';
import { CommentsActionType } from '@/infra/api/comments/actionTypes';
import { LoginComponentSourceName } from '@/presentation/components/Authentication/types';
import SAVE_BANNER_DESKTOP from 'public/assets/pt-br/login/login-save-banner.png';
import COMMENT_BANNER_DESKTOP from 'public/assets/pt-br/login/login-comment-banner.png';
import ALERT_BANNER_DESKTOP from 'public/assets/pt-br/login/login-alert-banner.png';
import FOLLOW_BANNER_DESKTOP from 'public/assets/pt-br/login/login-follow-banner.png';
import VOTE_BANNER_DESKTOP from 'public/assets/pt-br/login/login-vote-banner.png';
import SAVE_BANNER_DESKTOP_MOBILE from 'public/assets/pt-br/login/login-save-banner-mobile.png';
import COMMENT_BANNER_DESKTOP_MOBILE from 'public/assets/pt-br/login/login-comment-banner-mobile.png';
import ALERT_BANNER_DESKTOP_MOBILE from 'public/assets/pt-br/login/login-alert-banner-mobile.png';
import FOLLOW_BANNER_DESKTOP_MOBILE from 'public/assets/pt-br/login/login-follow-banner-mobile.png';
import VOTE_BANNER_DESKTOP_MOBILE from 'public/assets/pt-br/login/login-vote-banner-mobile.png';
import BANNER_DESKTOP from 'public/assets/pt-br/login/login-banner.png';
import BANNER_MOBILE from 'public/assets/pt-br/login/login-banner-mobile.png';
import BANNER_DESKTOP_NINJA from 'public/assets/en-us/login/login-banner.png';
import BANNER_MOBILE_NINJA from 'public/assets/en-us/login/login-banner-mobile.png';
import { AlertActionType } from '@/infra/api/alerts/actionTypes';
import { FollowersActionType } from '@/infra/api/followers/actionTypes';
import { SavedDealsActionType } from '@/infra/api/savedDeals/actionTypes';
import { VoteActionType } from '@/infra/api/votes/actionTypes';

export const ActionToTitle: Record<string, string> = {
  [VoteActionType.Vote]: 'auth-vote-message',
  [VoteActionType.RemoveVote]: 'auth-vote-message',
  [FollowersActionType.FollowUser]: 'auth-follow-message',
  [FollowersActionType.UnfollowUser]: 'auth-follow-message',
  [SavedDealsActionType.SaveDeal]: 'auth-save-message',
  [SavedDealsActionType.RemoveSavedDeal]: 'auth-save-message',
  [SavedDealsActionType.UpdateSavedDeal]: 'login-title-modal',
  [AlertActionType.CreateAlert]: 'auth-alert-message',
  [AlertActionType.DeleteAlert]: 'auth-alert-message',
  [CommentsActionType.PostNewComment]: 'auth-comment-message',
  [CommentsActionType.PostReactComment]: 'auth-comment-message',
  [CommentsActionType.PostReportComment]: 'auth-comment-message',
  [CommentsActionType.PostReplyComment]: 'auth-comment-message',
};

export const ActionToComponentSourceName: Record<
  string,
  LoginComponentSourceName
> = {
  [VoteActionType.Vote]: LoginComponentSourceName.modalVote,
  [VoteActionType.RemoveVote]: LoginComponentSourceName.modalVote,
  [FollowersActionType.FollowUser]: LoginComponentSourceName.modalFollow,
  [FollowersActionType.UnfollowUser]: LoginComponentSourceName.modalFollow,
  [SavedDealsActionType.SaveDeal]: LoginComponentSourceName.modalSave,
  [SavedDealsActionType.RemoveSavedDeal]: LoginComponentSourceName.modalSave,
  [SavedDealsActionType.UpdateSavedDeal]: LoginComponentSourceName.modalSave,
  [AlertActionType.CreateAlert]: LoginComponentSourceName.modalAlert,
  [AlertActionType.DeleteAlert]: LoginComponentSourceName.modalAlert,
  [CommentsActionType.PostNewComment]: LoginComponentSourceName.modalComment,
  [CommentsActionType.PostReactComment]: LoginComponentSourceName.modalComment,
  [CommentsActionType.PostReportComment]: LoginComponentSourceName.modalComment,
  [CommentsActionType.PostReplyComment]: LoginComponentSourceName.modalComment,
};

export const ActionToSubtitle: Record<string, string> = {
  [VoteActionType.Vote]: 'auth-vote-subtitle-message',
  [VoteActionType.RemoveVote]: 'auth-vote-subtitle-message',
  [FollowersActionType.FollowUser]: 'auth-follow-subtitle-message',
  [FollowersActionType.UnfollowUser]: 'auth-follow-subtitle-message',
  [SavedDealsActionType.SaveDeal]: 'auth-save-subtitle-message',
  [SavedDealsActionType.RemoveSavedDeal]: 'auth-save-subtitle-message',
  [SavedDealsActionType.UpdateSavedDeal]: 'auth-modal-subtitle',
  [AlertActionType.CreateAlert]: 'auth-alert-subtitle-message',
  [AlertActionType.DeleteAlert]: 'auth-alert-subtitle-message',
  [CommentsActionType.PostNewComment]: 'auth-comment-subtitle-message',
  [CommentsActionType.PostReactComment]: 'auth-comment-subtitle-message',
  [CommentsActionType.PostReportComment]: 'auth-comment-subtitle-message',
  [CommentsActionType.PostReplyComment]: 'auth-comment-subtitle-message',
};

export const getAuthModalBannerImageData = ({
  isMobile,
  actionType,
  locale,
}: {
  isMobile: boolean;
  actionType: string;
  locale?: string;
}) => {
  const bannerDataMap: Record<
    string,
    { banner: StaticImageData; altImage: string }
  > = {
    [VoteActionType.Vote]: {
      banner: isMobile ? VOTE_BANNER_DESKTOP_MOBILE : VOTE_BANNER_DESKTOP,
      altImage: 'auth-vote-banner-alt-text',
    },
    [VoteActionType.RemoveVote]: {
      banner: isMobile ? VOTE_BANNER_DESKTOP_MOBILE : VOTE_BANNER_DESKTOP,
      altImage: 'auth-vote-banner-alt-text',
    },
    [FollowersActionType.FollowUser]: {
      banner: isMobile ? FOLLOW_BANNER_DESKTOP_MOBILE : FOLLOW_BANNER_DESKTOP,
      altImage: 'auth-follow-banner-alt-text',
    },
    [FollowersActionType.UnfollowUser]: {
      banner: isMobile ? FOLLOW_BANNER_DESKTOP_MOBILE : FOLLOW_BANNER_DESKTOP,
      altImage: 'auth-follow-banner-alt-text',
    },
    [SavedDealsActionType.SaveDeal]: {
      banner: isMobile ? SAVE_BANNER_DESKTOP_MOBILE : SAVE_BANNER_DESKTOP,
      altImage: 'auth-save-banner-alt-text',
    },
    [SavedDealsActionType.RemoveSavedDeal]: {
      banner: isMobile ? SAVE_BANNER_DESKTOP_MOBILE : SAVE_BANNER_DESKTOP,
      altImage: 'auth-save-banner-alt-text',
    },
    [SavedDealsActionType.UpdateSavedDeal]: {
      banner: isMobile ? BANNER_MOBILE : BANNER_DESKTOP,
      altImage: 'default-banner-alt-text',
    },
    [AlertActionType.CreateAlert]: {
      banner: isMobile ? ALERT_BANNER_DESKTOP_MOBILE : ALERT_BANNER_DESKTOP,
      altImage: 'auth-alert-banner-alt-text',
    },
    [AlertActionType.DeleteAlert]: {
      banner: isMobile ? ALERT_BANNER_DESKTOP_MOBILE : ALERT_BANNER_DESKTOP,
      altImage: 'auth-alert-banner-alt-text',
    },
    [CommentsActionType.PostNewComment]: {
      banner: isMobile ? COMMENT_BANNER_DESKTOP_MOBILE : COMMENT_BANNER_DESKTOP,
      altImage: 'auth-comment-banner-alt-text',
    },
    [CommentsActionType.PostReactComment]: {
      banner: isMobile ? COMMENT_BANNER_DESKTOP_MOBILE : COMMENT_BANNER_DESKTOP,
      altImage: 'auth-comment-banner-alt-text',
    },
    [CommentsActionType.PostReportComment]: {
      banner: isMobile ? COMMENT_BANNER_DESKTOP_MOBILE : COMMENT_BANNER_DESKTOP,
      altImage: 'auth-comment-banner-alt-text',
    },
    [CommentsActionType.PostReplyComment]: {
      banner: isMobile ? COMMENT_BANNER_DESKTOP_MOBILE : COMMENT_BANNER_DESKTOP,
      altImage: 'auth-comment-banner-alt-text',
    },
  };

  const defaultBannerData = {
    banner: isMobile ? BANNER_MOBILE : BANNER_DESKTOP,
    altImage: 'default-banner-alt-text',
  };

  const defaultBannerDataNinja = {
    banner: isMobile ? BANNER_MOBILE_NINJA : BANNER_DESKTOP_NINJA,
    altImage: 'default-banner-alt-text',
  };

  const bannerData = bannerDataMap[actionType] || defaultBannerData;

  return locale === 'en-US' ? defaultBannerDataNinja : bannerData;
};
