import { Colors, SmallHeavy, SmallLight, Theme } from '@pelando/components';
import styled from 'styled-components';

export const BOTTOM_NAVIGATION_HEIGHT = 108;

export const Container = styled.nav`
  ${SmallLight}
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 24px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 16px;
  height: ${BOTTOM_NAVIGATION_HEIGHT}px;
  border-top: 1px solid rgb(${Theme.colors.Golf});
  background: rgb(${Theme.colors.Juliet});
`;

export const Anchor = styled.a`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  ${SmallLight}
  text-decoration: none;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  color: rgb(${Colors.Gray});
  background: none;
  position: relative;
  z-index: 3;

  &[aria-current='true'] {
    i {
      color: rgb(${Theme.colors.Brand});
    }

    & > span {
      ${SmallHeavy}
      color: rgb(${Theme.colors.Alpha});
    }
  }
`;

export const NavigationItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
