import { isComparatorHomeEnabled } from '@/presentation/services/featureFlags';
import { useRouter } from 'next/router';

export const enum PathOptions {
  Home = '/[slug]',
  Post = '/postar',
  Comparator = '/comparador',
  Alerts = '/meus-alertas',
}

export function useCurrenActiveItemByPath() {
  const { pathname } = useRouter();
  const currentPathOptions = isComparatorHomeEnabled()
    ? [
        PathOptions.Home,
        PathOptions.Post,
        PathOptions.Comparator,
        PathOptions.Alerts,
      ]
    : [PathOptions.Home, PathOptions.Post, PathOptions.Alerts];

  const activeIndex = currentPathOptions.indexOf(pathname as PathOptions);
  const activePath: PathOptions | undefined = currentPathOptions[activeIndex];

  return { activeIndex, activePath };
}
