import { NavigationItemParams } from '@/presentation/components/BottomNavigation/component';
import { NavigationIcon } from '@/presentation/components/BottomNavigation/components/NavigationIcon';
import { Anchor } from '@/presentation/components/BottomNavigation/style';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Grid2 } from '@pelando/fontawesome/icons';

export function CategoriesNavigationItem({
  onClick,
  active,
}: NavigationItemParams) {
  const { t } = useTranslation('header');

  return (
    <Anchor as="button" aria-current={active} onClick={onClick}>
      <NavigationIcon icon={Grid2} />
      <span>{t("categories-link-text'")}</span>
    </Anchor>
  );
}
