import { BannerType, HEADER_HEIGHT, useToast } from '@pelando/components';
import { useCallback, useEffect } from 'react';
import { getOnboardingStageLocalStorage } from '@/infra/storage/onboardingManagement';
import { getDealOnboardingStageLocalStorage } from '@/infra/storage/dealOnboardingStorage';
import { useAuthModal } from '../../components/Authentication/hooks/useAuthModal';
import { ConfirmEmailModal } from '../../components/ConfirmEmailModal';
import { sendEmailConfirmation } from '../../../infra/api/auth/endpoints';
import { EmailNotConfirmedSubject$ } from '../../../infra/api/core/guards/authGuard';
import { useTranslation } from '../useTranslation';

export function useUnconfirmedEmailGuardModal({
  onConfirm,
  onClose,
}: {
  onConfirm?: () => void;
  onClose?: () => void;
}) {
  const { showModal, closeModal } = useAuthModal();
  const hasOnboardModalShown =
    !getOnboardingStageLocalStorage() && !getDealOnboardingStageLocalStorage();

  const showConfirmEmail = useCallback(
    () => {
      showModal(
        <ConfirmEmailModal
          hideCancel
          onConfirm={() => {
            onConfirm?.();
            closeModal();
          }}
          onBackToHome={() => {
            closeModal();
          }}
        />,
        { onClose }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose, onConfirm]
  );

  useEffect(() => {
    const subscription = EmailNotConfirmedSubject$.subscribe(() => {
      if (hasOnboardModalShown) showConfirmEmail();
    });

    return () => subscription.unsubscribe();
  }, [showConfirmEmail, hasOnboardModalShown]);
}

export default function useEnhancedUnconfirmedEmailGuardModal() {
  const { t } = useTranslation('common');
  const { showToast } = useToast();

  const handleSendEmailConfirmation = useCallback(
    () =>
      sendEmailConfirmation
        .requestAsPromise()
        .then(() =>
          showToast({
            topPosition: HEADER_HEIGHT,
            bannerType: BannerType.SUCCESS,
            titleText: t('use-unconfirmed-email-guard-modal-title'),
            text: t('use-unconfirmed-email-guard-modal-text'),
            duration: 5000,
          })
        )
        .catch(() => {
          showToast({
            topPosition: HEADER_HEIGHT,
            bannerType: BannerType.ERROR,
            titleText: 'Oops!',
            text: t('use-unconfirmed-email-guard-modal-error-text'),
            duration: 5000,
          });
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showToast]
  );

  useUnconfirmedEmailGuardModal({
    onConfirm: handleSendEmailConfirmation,
  });
}
