import { ReactNode, useCallback, useEffect } from 'react';
import { filter } from 'rxjs';
import { useMediaQuery, MediaQuery } from '@pelando/components';
import { BannerImage } from '@/presentation/components/Authentication/hooks/useAuthModal/styles';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { AuthGuardSubject$ } from '../../../infra/api/core/guards/authGuard';
import { useAuthModal } from '../../components/Authentication/hooks/useAuthModal';
import {
  AuthKind,
  LoginComponentSourceName,
} from '../../components/Authentication/types';
import {
  ActionToComponentSourceName,
  ActionToSubtitle,
  ActionToTitle,
  getAuthModalBannerImageData,
} from './messages';
import { useTranslation } from '../useTranslation';

const AuthModal = dynamic(
  () =>
    import('@/presentation/components/Authentication').then(
      (mod) => mod.default
    ),
  { ssr: false }
);

type UseAuthGuardModalProps = {
  googleClientId?: string;
};

export function useAuthGuardModal({ googleClientId }: UseAuthGuardModalProps) {
  const { showModal, closeModal } = useAuthModal();
  const { t } = useTranslation('login');
  const { locale } = useRouter();
  const isMobile = useMediaQuery(MediaQuery.SCREEN_SM);

  const showAuthModal = useCallback(
    ({
      message,
      subtitle,
      componentName,
      bannerImage,
      retry,
    }: {
      message: ReactNode;
      subtitle: ReactNode;
      componentName: LoginComponentSourceName;
      bannerImage: ReactNode;
      retry: () => void;
    }) => {
      showModal(
        <AuthModal
          kind={AuthKind.LOGIN}
          modalTitle={message}
          modalSubtitle={subtitle}
          componentSourceName={componentName}
          onFinishSuccessfully={() => retry()}
          onCloseModal={closeModal}
          googleClientId={googleClientId}
        />,
        { bannerImage }
      );
    },
    [closeModal, showModal, googleClientId]
  );

  // NEW WAY
  useEffect(() => {
    const subscription = AuthGuardSubject$.pipe(
      filter(({ id }) => !!ActionToTitle[id])
    ).subscribe(({ id, retry }) => {
      const bannerData = getAuthModalBannerImageData({
        actionType: id,
        isMobile,
        locale,
      });
      return showAuthModal({
        message: t(ActionToTitle[id]),
        subtitle: t(ActionToSubtitle[id]),
        componentName: ActionToComponentSourceName[id],
        bannerImage: (
          <BannerImage src={bannerData.banner} alt={t(bannerData.altImage)} />
        ),
        retry,
      });
    });

    return () => subscription.unsubscribe();
  }, [isMobile, locale, showAuthModal, t]);
}
