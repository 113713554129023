import { NavigationItemParams } from '@/presentation/components/BottomNavigation/component';
import { NavigationIcon } from '@/presentation/components/BottomNavigation/components/NavigationIcon';
import { Anchor } from '@/presentation/components/BottomNavigation/style';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { CircleHeart } from '@pelando/fontawesome/icons';
import { useRef } from 'react';

export function CommunitiesNavigationItem({
  onClick,
  active,
}: NavigationItemParams) {
  const { t } = useTranslation('header');
  const communityRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={communityRef}>
      <Anchor as="button" aria-current={active} onClick={onClick}>
        <NavigationIcon icon={CircleHeart} />
        <span>{t('communities-link-text')}</span>
      </Anchor>
    </div>
  );
}
