import { MediaQuery, Theme } from '@pelando/components';
import styled from 'styled-components';
import BottomNavigationEnhanced, {
  BOTTOM_NAVIGATION_HEIGHT,
} from '../components/BottomNavigation';
import { HEADER_HEIGHT } from '../components/Header/styles';

export const LayoutContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  background: rgb(${Theme.colors.Kilo});
`;

export const MainContainer = styled.main`
  width: 100vw;
  height: 100%;
  padding-top: ${HEADER_HEIGHT}px;

  &[data-hidden-layout='true'] {
    padding-top: 0;
  }
`;

export const FixedBottomNavigation = styled(BottomNavigationEnhanced)`
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 64px;

  @supports (height: env(safe-area-inset-bottom)) {
    height: calc(env(safe-area-inset-bottom) + ${BOTTOM_NAVIGATION_HEIGHT}px);
    padding-bottom: env(safe-area-inset-bottom);
  }

  @media ${MediaQuery.SCREEN_LG_UP} {
    && {
      display: none;
    }
  }
`;
