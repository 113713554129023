import { useEffect } from 'react';
import { BannerType, useModal, useToast } from '@pelando/components';
import getHoursAgoFromDate from '../../services/time/getHoursAgoFromDate';
import { UpdateTermsModalContent } from './compoonent';
import { mediumScreenModalStyle } from '../../styles/shared/modal';
import { HEADER_HEIGHT } from '../../components/Header/styles';
import { useTranslation } from '../useTranslation';

type UseAgreeWithPrivacyPolicyModalProps = {
  isLogged: boolean;
  agreedWithPrivacyPolicy: boolean;
  createdAt: string;
  route: string;
  agreeWithLastPrivacyTerms: () => Promise<boolean>;
};

const protectedRoutes = [
  '/sobre/codigo-de-conduta',
  '/sobre/politica-de-privacidade',
  '/sobre/termos-de-uso',
];

const hasMoreThanTwelveHours = (date: string) => {
  if (!date) return false;
  return getHoursAgoFromDate(date) > 12;
};

export function useAgreeWithPrivacyPolicyModal({
  isLogged,
  agreedWithPrivacyPolicy,
  createdAt,
  agreeWithLastPrivacyTerms,
  route,
}: UseAgreeWithPrivacyPolicyModalProps) {
  const { t } = useTranslation('login');
  const { showToast } = useToast();
  const { showModal, closeModal } = useModal();

  const handleAgreeWithLastPrivacyTerms = () => {
    closeModal();
    agreeWithLastPrivacyTerms().catch(() => {
      showToast({
        bannerType: BannerType.ERROR,
        titleText: t('login-toast-banner-error-title'),
        topPosition: HEADER_HEIGHT,
        text: t('login-toast-banner-error-text'),
      });
    });
  };

  useEffect(() => {
    if (!isLogged || agreedWithPrivacyPolicy || protectedRoutes.includes(route))
      return;
    if (hasMoreThanTwelveHours(createdAt)) {
      showModal(
        <UpdateTermsModalContent onAgree={handleAgreeWithLastPrivacyTerms} />,
        { style: mediumScreenModalStyle, preventClose: true }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, agreedWithPrivacyPolicy, hasMoreThanTwelveHours, route]);
}
