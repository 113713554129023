import { ShieldCheck } from '@pelando/fontawesome/icons';
import {
  ConfirmButton,
  Container,
  Content,
  ShieldCheckIcon,
  Title,
} from './styles';
import { useTranslation } from '../useTranslation';
import { AgreementType } from '../../../domain/entities/Agreement';
import ExternalAgreementLink from '../../components/Authentication/components/ExternalAgreementLink';
import Translation from '../../components/Translation';

export type ModalContentProps = {
  onAgree: () => void;
};

export const UpdateTermsModalContent = ({ onAgree }: ModalContentProps) => {
  const { t } = useTranslation('login');
  return (
    <Container>
      <ShieldCheckIcon icon={ShieldCheck} />
      <Title>{t('policy-update')}</Title>
      <Content>
        <Translation translation={t('policy-update-text')}>
          Fizemos isso para que novos recursos sejam abrangidos no Pelando.
          Clique em Continuar para concordar com os nossos
          <ExternalAgreementLink
            agreementType={AgreementType.TERMS_OF_SERVICE}
          />
          ,
          <ExternalAgreementLink
            agreementType={AgreementType.CODE_OF_CONDUCT}
          />
          e
          <ExternalAgreementLink agreementType={AgreementType.PRIVACY_POLICY} />
          .
        </Translation>
      </Content>
      <ConfirmButton onClick={onAgree}>{t('agree-new-policy')}</ConfirmButton>
    </Container>
  );
};
