import { ReactElement, cloneElement } from 'react';
import { Container, NavigationItem } from './style';

export type NavigationItemParams = {
  active?: boolean;
  count?: number;
  onClick?: () => void;
};

export type BottomNavigationProps = {
  className?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  navigationItems: ReactElement[];
  activeIndex: number;
};

function BottomNavigation({
  className,
  innerRef,
  activeIndex,
  navigationItems,
}: BottomNavigationProps) {
  return (
    <Container className={className} ref={innerRef}>
      {navigationItems.map((item, index) => (
        <NavigationItem key={item.key}>
          {cloneElement(item, { active: index === activeIndex })}
        </NavigationItem>
      ))}
    </Container>
  );
}

export default BottomNavigation;
