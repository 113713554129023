/* eslint-disable no-console */
import { isMobileBrowser } from '@/presentation/services/runtime';
import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from 'firebase/messaging';
import Cookies from 'js-cookie';
import { DeviceType } from '@/domain/entities/Notification';
import env from '@/config/environment';
import { subscribeDeviceMutation } from '../api/notifications/endpoints';

export type FcmMessage = {
  from: string;
  notification: Notification;
  priority: 'high' | 'normal';
};

type Notification = {
  body: string;
  title: string;
  link?: string;
};

declare global {
  interface Window {
    __FIREBASE_MSG__?: Messaging;
    __FIREBASE_TOKEN__?: string;
  }
}

const LOCAL_STORAGE_IGNORE_KEY = 'ignoreWebpush';

const isSupported = () =>
  window.navigator.serviceWorker && window.Notification && !isMobileBrowser();

/**
 * Gets the Firebase Messaging token, passing it to a handler for further use.
 * Added async/await for more modern and consistent syntax.
 */
export async function getFirebaseMessagingToken(
  messaging: Messaging,
  handleToken: (token: string) => void
) {
  try {
    const token = await getToken(messaging);
    window.__FIREBASE_TOKEN__ = token;
    handleToken(token);
  } catch (error) {
    console.error('Error fetching FCM token:', error);
  }
}

/**
 * Displays a web notification using the FCM message data.
 * Handles notification click to open the associated link.
 */
export function showNotification(messaging: Messaging, data: FcmMessage): void {
  const { title, body, link } = data.notification;

  const notification = new Notification(title, {
    body,
    icon: '/imgs/logo.png',
    tag: 'pelando_notification',
  });

  notification.onclick = () => {
    window.open(link || window.location.origin, '_blank');
    notification.close();
  };
}

/**
 * Determines if the app should request notification permissions.
 * Takes into account if the user has previously chosen an answer and if they are logged in.
 */
export function shouldRequestNotificationPermission(): Promise<boolean> {
  return new Promise((resolve) => {
    const hasAnswered = window.localStorage.getItem(LOCAL_STORAGE_IGNORE_KEY);
    const isLogged = Cookies.get('probably_logged') === 'true';

    if (
      hasAnswered === 'true' ||
      !isLogged ||
      Notification.permission !== 'default'
    ) {
      resolve(false);
    } else {
      resolve(true);
    }
  });
}

const sendTokenToBackend = (token: string) => {
  if (!token) {
    return;
  }

  console.log(`[WebPush] received FCM token: ${token}`);

  subscribeDeviceMutation
    .requestAsPromise({
      device: { fcmToken: token, type: DeviceType.BROWSER },
    })
    .then(() => {
      console.log('[WebPush] subscribed device to backend');
    })
    .catch((reason) => console.error('Error subscribing device:', reason));
};

/**
 * Loads and initializes the Firebase app with the given configuration.
 */
async function loadFirebase() {
  const firebaseConfig = env.FIREBASE_CONFIG! as unknown as string;
  return initializeApp(JSON.parse(firebaseConfig));
}

async function initFirebaseMessaging() {
  const firebaseApp = await loadFirebase();
  const messaging = getMessaging(firebaseApp);
  window.__FIREBASE_MSG__ = messaging;
  return messaging;
}

/**
 * Watches for incoming FCM notifications if permission has been granted.
 */
export async function setupWebpushHandlers() {
  if (!isSupported) {
    return;
  }

  if (Notification.permission !== 'granted') {
    console.log(
      '[WebPush] notification permission was not granted or requested'
    );
    return;
  }

  console.log('[WebPush] initializing firebase');

  const messaging = await initFirebaseMessaging();

  await getFirebaseMessagingToken(messaging, sendTokenToBackend);

  // TODO: Check for multiple event listeners
  onMessage(messaging, (payload) => {
    console.log('[WebPush] received a message', payload);
    showNotification(messaging, payload as unknown as FcmMessage);
  });
}

/**
 * Requests webpush permissions and setup the appropriate listeners if granted
 */
export async function requestWebPushPermissions() {
  const requestPermission = await shouldRequestNotificationPermission();

  if (requestPermission) {
    await Notification.requestPermission();
  }

  // attempts to set the listeners again
  setupWebpushHandlers();
}

export async function setDoNotShowWebpushModalAgain() {
  window.localStorage.setItem(LOCAL_STORAGE_IGNORE_KEY, 'true');
}
