import { IconDefinition } from '@pelando/fontawesome';
import { Icon } from './styles';
import { CountBadgeIcon } from '../../../CountBadgeIcon';

export const NavigationIcon = ({
  icon,
  count,
}: {
  icon: IconDefinition;
  count?: number;
}) => <CountBadgeIcon icon={<Icon icon={icon} />} count={count} />;
